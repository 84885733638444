const Permissions = {
  notification_permission: {
    lmia: 1,
    visa: 1,
    interview: 1,
    job: 1,
  },
  email_permission: {
    lmia: 1,
    visa: 1,
    interview: 1,
    job: 1,
  },
};
export default Permissions;
