import React from "react";
import UserRegisterPage from "../common/userRegisterPage";

function SinUpPage() {
  return (
    <div>
      {/* <link
        rel="stylesheet"
        href="https://canjobs.vercel.app/css/bootstrap.css"
      />
      <link rel="stylesheet" href="https://canjobs.vercel.app/css/main.css" /> */}
      <UserRegisterPage />
    </div>
  );
}

export default SinUpPage;
